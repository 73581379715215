/* Ensure the image fully fits within the container */
.carousel-item img {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    object-fit: contain; /* Show the entire image without zooming or cropping */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 1024px) {
    .carousel-item img {
      height: 70vh; /* Adjust height for tablets */
    }
  }
  
  @media (max-width: 768px) {
    .carousel-item img {
      height: 60vh; /* Adjust height for smaller tablets */
    }
  }
  
  @media (max-width: 576px) {
    .carousel-item img {
      height: 50vh; /* Adjust height for mobile devices */
    }
  }
  