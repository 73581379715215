* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    width: 100%;
}


#homepage1 {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;

  }

  #homepage1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); 
    z-index: 1;
  }
  
  .slider-item {
    position: relative;
    height: 80vh;
  }
  
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .slider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
  }
  
  .heading {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .subheading {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
  .homepage1-heading-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #000;
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .heading {
      font-size: 1.8rem;
    }
  
    .subheading {
      font-size: 1.2rem;
    }
  
    .homepage1-heading-btn {
      font-size: 0.9rem;
    }
  }
  


/* Master Section */
#homepage2 {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
}

#masterdiv {
    height: max-content;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

#topcourse {
    margin-top: 1rem;
    height: max-content;
    width: 100%;   
}


#courseheading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 1.3rem;
  }
  
  #courseheadings {
    text-align: left;
  }
  
  .courseheading1 {
    font-size: 2rem;
    margin: 0;
  }
  
  .subheadingpage2 {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .course--btn {
    padding: 10px 30px;
    font-size: 1.2rem;
    font-weight: 550;
    color: #3b3939;
    border: 3px solid grey;
    
    border-radius: 25px;
    text-decoration: none;
  } 
  
  .course--btn:hover {
   color: #29166F;
   border-color: #29166F;
    
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    #courseheading {
      padding: 15px;
    }
  
    .courseheading1 {
      font-size: 1.8rem;
    }
  
    .subheadingpage2 {
      font-size: 1.1rem;
    }
  
    .course-btn {
      padding: 8px 18px;
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 768px) {
    #courseheading {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .courseheading1 {
      font-size: 1.6rem;
    }
  
    .subheadingpage2 {
      font-size: 1rem;
    }
  
    .course-btn {
      padding: 8px 18px;
      font-size: 0.9rem;
      margin-top: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .courseheading1 {
      font-size: 1.4rem;
    }
  
    .subheadingpage2 {
      font-size: 0.9rem;
    }

  
    .course-btn {
      padding: 7px 16px;
      font-size: 0.85rem;
    }
  }
  


  #coursecardparent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards */
    max-width: 1200px; /* Limit the width of the card container */
    margin: 0 auto; /* Center the container on the page */
    padding: 20px;
    margin-top: 2rem;
    gap: 20px; /* Space between the cards */
  }
  
  .mycard {
    position: relative;
    max-width: 300px;
    width: 100%;
    height: 210px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 9px 15px rgba(0, 0, 0, 0.3);
    transition: 0.4s ease-in-out;
    justify-content: flex-end;
    align-items: center;
    overflow: visible;
    border-radius: 10px;
    margin-bottom: 3rem;
  }
  
  .mycard:hover {
    height: 360px;
  }
  
  .cimg {
    position: absolute;
    width: 250px;
    height: 200px;
    top: -40px; /* Move the image 10px above the card */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    transition: 0.4s ease-in-out;
  }
  
  .cimg img {
    max-width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  
  .cdetail {
    margin-top: 100px;
    padding: 10px 15px;
    text-align: center;
    color: black;
    font-family: monospace;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s ease-in-out;
  }
  
  .mycard:hover .cdetail {
    visibility: visible;
    opacity: 1;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    #coursecardparent {
      max-width: 100%;
      gap: 10px;
      margin-top: 5rem;
    }
  
    .mycard { 
      width: 100%;
      max-width: 90%;
    }
  
    .mycard:hover {
      height: 350px;
    }
  
    .cimg {
      width: 120px;
      height: 120px;
      top: -50px; /* Adjusted for smaller screens */
    }
  }

  @media (max-width: 480px){
    .mycard{
      height: 20vh;
    }
  }
  






  .course-name {
    font-size: 2px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .course-type,
  .course-duration,
  .course-eligibility {
    font-size: 16px;
    margin-bottom: 8px;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .course-card {
      width: 45%; /* For tablets and smaller laptops */
    }
  }
  
  @media (max-width: 768px) {
    .course-card {
      width: 100%; /* For mobile and smaller tablets */
    }
  }
  
  @media (max-width: 480px) {
    .course-card {
      width: 100%; /* For small mobile screens */
      margin: 10px 0;
    }
  
    .course-content {
      padding: 15px;
    }
  
    .course-name {
      font-size: 20px;
    }
  }
  

  
/* course section ending */


/* Home Page Poster */
#posterHome {
    height: 35vh;
    width: 100%;
    background-image: url("../../Assets/Homepageposter.png");
    background-size: cover;
    background-position:center;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-repeat: no-repeat;
    padding: 2rem;
}

#studentlogosection {
    display: flex;
    align-items: center;
    gap: 20px;
}

#buttonsection {
    display: flex;
    gap: 20px;
}

.postercontect-btn {
    padding: 10px 30px;
    font-size: 1.2rem;
    font-weight: 550;
    background: none;
    border: 2.5px solid #29166F;
    border-radius: 25px;
    color: #29166F;
    text-decoration: none;
}

.posterviewcourses-btn {
    padding: 10px 30px;
    font-size: 1.2rem;
    font-weight: 550;
    background-color: #29166F;
    color: #FBEAEB;
    border: none;
    border-radius: 25px;
    text-decoration: none;
}

.posterviewcourses-btn:hover{
  border: 2px solid rgb(5, 242, 163);
}


/* Tablet */
@media (max-width: 768px) {
    #posterHome {
        height: max-content;
        flex-direction: column;
        padding: 1.5rem;
        text-align: center;
    }

    

    #studentlogosection {
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }

    #buttonsection {
        flex-direction: column;
        gap: 10px;
    }

    .postercontect-btn, .posterviewcourses-btn {
        padding: 8px 20px;
        font-size: 1rem;
    }
}

/* Mobile */
@media (max-width: 480px) {
    #posterHome {
        padding: 1rem;
        height: max-content;
    }

    #studentlogosection {
        gap: 5px;
    }

    #buttonsection {
        gap: 10px;
    }

    .postercontect-btn, .posterviewcourses-btn {
        padding: 6px 15px;
        font-size: 0.9rem;
    }

    
}

/* Poster-2 */

#posterHome2 {
  height: 35vh;
  width: 100%;
  background-image: url("../../Assets/Homepageposter.png");
  background-size: cover;
  background-position:center;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-repeat: no-repeat;
  padding: 2rem;
}


.StudentGroup {
    height: 35vh;
}

#Posterdescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.poster2-btn {
  padding: 10px 30px;
  font-size: 1.2rem;
  font-weight: 550;
  background-color: #29166F;
  color: #FBEAEB;
  border: none;
  border-radius: 25px;
  text-decoration: none;
  margin-top: 2rem;
  border: 2px solid #29166F;
  transition: 0.3s;
}

.poster2-btn:hover{
  border: 2px solid rgb(5, 242, 163); 
}


@media (max-width:1368px){
  #posterHome2{
    height: 80vh;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width:768px){
    .StudentGroup{
        height: 33vh;
        width: 80vw;
    }

    .poster2-btn{
      padding: 15px 20px;
      font-size: 1rem;
    }
}

@media (max-width:480px){
    .StudentGroup{
        height: auto;
    }
    #posterHome2{
      height: max-content;
      width: 100%;
    }

    .poster2-btn{
      padding: 10px 15px;
      font-size: 0.8rem;
    }
}



/* Achievements Section start */
#achivment-section {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem; 
    justify-content: center;
    padding: 2rem;
  }
  
  /* Achievement Card */
  #achivment-active-student-card {
    background-color: #f8f9fa; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    flex: 1 1 calc(25% - 2rem); 
    max-width: 300px; 
  }
  

  .achivment-heading {
    font-size: 2rem; 
    margin-bottom: 0.5rem;
  }
  
  .achivment-subheading {
    font-size: 1.2rem; 
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    #achivment-active-student-card {
      flex: 1 1 calc(25% - 2rem); /* 3 cards per row */
      max-width: 250px;
    }
  }
  
  @media (max-width: 768px) {
    #achivment-active-student-card {
      flex: 1 1 calc(50% - 2rem); /* 2 cards per row */
      max-width: 220px;
    }
  }
  
  @media (max-width: 480px) {
    #achivment-active-student-card {
      flex: 1 1 100%; /* 1 card per row */
      max-width: 100%;
    }
  
    .achivment-heading {
      font-size: 1.5rem; /* Smaller font size */
    }
  
    .achivment-subheading {
      font-size: 1rem; /* Smaller font size */
    }
  }
  

/* Certification Section */
#certification-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    gap: 2rem;
}

#certification-image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.certificationicon {
    width: 100%;

}

#certification-description-section {
    flex: 2;
}

#certification-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#certification-description-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

#certification-description-content {
    margin-bottom: 1.5rem;
}

#certification-content-subheading {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

#certification-courses-name-section {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.certification-courses-name-section {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.certification-png img {
    max-width: 50px;
    height: auto;
}

.certification-png .certification-course-name {
    font-size: 1rem;
}

#certification-description-button {
    margin-top: 1rem;
}

.certification-btn {
  padding: 10px 30px;
  font-size: 1.2rem;
  font-weight: 550;
  color: black;
  border: 3px solid silver;
  border-radius: 25px;
  text-decoration: none;
}

.certification-btn:hover{
  color: #29166F;
   border-color: #29166F;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    #certification-section {
        flex-direction: column;
        text-align: center;
        padding: 1.5rem;
    }

    #certification-description-heading {
        font-size: 1.8rem;
    }


    #certification-description-content {
        margin: 1rem 0;
    }

    #certification-courses-name-section {
        flex-direction: column;
        align-items: center;
    }

    .certification-courses-name-section {
        justify-content: center;
        gap: 0.5rem;
    }

    .course-name {
        font-size: 0.9rem;
    }

    .certification-btn {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
}

@media (max-width: 768px) {
    #certification-section {
        padding: 1rem;
    }

    #certification-description-heading {
        font-size: 1.6rem;
    }

    #certification-description-content {
        margin: 0.5rem 0;
    }

    .course-name {
        font-size: 0.8rem;
    }

    .certification-btn {
        font-size: 0.8rem;
        padding: 6px 12px;
    }
}

@media (max-width: 480px) {
    #certification-section {
        padding: 0.5rem;
    }

    #certification-description-heading {
        font-size: 1.4rem;
    }

    #certification-description-content {
        margin: 0.5rem 0;
    }

    .certification-courses-name-section {
        flex-direction: column;
        gap: 0.5rem;
    }

    .course-name {
        font-size: 0.7rem;
    }

    .certification-btn {
        font-size: 0.9rem;
        padding: 13px 25px;
    }
}


/* Feedback Section */
#student-feedback-section {
    height: auto;
    width: 100%;
}

#studen-feedback-heading {
    height: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.student-feedback-card {
    height: 50vh;
    max-height: 60vh;
    width: 18.5rem;
    border: 2px solid lightgray;
    border-radius: 20px;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}

.student-feedback {
    padding-top: 25px;
    font-family: monospace;
    font-size: 1.1rem;
}

#feedback-card-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .student-feedback-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    max-height: 60vh;
    height: 50vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;

  }
  
  .student-feedback-card:hover {
    transform: scale(1.05);
  }
  
  .feedbacks {
    margin-bottom: 15px;
  }
  
  .feedbacker-name h2 {
    font-size: 1.5rem;
  }
  
  .feedbacker-course {
    font-size: 1rem;
    color: #888;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    #feedback-card-section {
      padding: 15px;
    }
  }
  
  @media (max-width: 768px) {
    #feedback-card-section {
      flex-direction: column;
      align-items: center;
    }
  
    .student-feedback-card {
      max-width: 80%;
      margin-bottom: 20px;
    }
  
    .feedbacker-name h2 {
      font-size: 1.4rem;
    }
  
    .feedbacker-course {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .student-feedback-card {
      max-width: 90%;
      padding: 15px;
    }
  
    .feedbacker-name h2 {
      font-size: 1.3rem;
    }
  
    .feedbacker-course {
      font-size: 0.85rem;
    }
  }


#typingPoster {
  margin-top: 3rem;
  height: 34vh;
  width: 100%;
  background-image: url("../../Assets/Homepageposter.png");
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-repeat: no-repeat;
  padding: 2rem;
  flex-direction: row;
}


.typingMaster {
  height: auto;
  width: auto;
  max-width: 400px; 
  padding: 20px;
}

#typingPosterDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Center text by default */
}

#typingPosterDescription h1, #typingPosterDescription h3 {
  color: rgb(21, 20, 20);
}

.typingPoster-btn {
  padding: 10px 30px;
  font-size: 1.2rem;
  font-weight: 550;
  background-color: #29166F;
  color: #FBEAEB;
  border: none;
  border-radius: 25px;
  text-decoration: none;
  margin-top: 2rem;
  border: 2px solid #29166F;
  transition: 0.3s;
}

.typingPoster-btn:hover {
  border: 2px solid rgb(5, 242, 163);
}


@media (min-width: 768px) {
  #typingPoster {
      height: 37vh;
      flex-direction: row;
      justify-content: space-around;
      padding: 3rem;
  }

  #typingPosterDescription {
      margin-left: 20px;
      text-align: left; 
  }

  .typingMaster {
      height: auto;
      max-width: 250px;
  }

  #typinglogosection img {
      height: 45vh;
  }

  .typingPoster-btn {
      padding: 12px 25px;
      font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  #typingPoster {
      height: auto;
      flex-direction: column; 
      justify-content: center;
      padding: 2rem;
      text-align: center;
  }

  #typinglogosection img {
      height: 40vh;
      width: auto;
  }

  #typingPosterDescription {
      margin-top: 1rem;
  }

  .typingMaster {
      width: 65vw;
      height: auto;
  }

  .typingPoster-btn {
      padding: 10px 20px;
      font-size: 1rem;
  }
}

@media (max-width: 480px) {
  #typingPoster {
      height: auto;
      padding: 1.5rem;
  }

  #typinglogosection img {
      height: 35vh;
  }

  .typingMaster {
      width: 70vw;
      height: auto;
  }

  .typingPoster-btn {
      padding: 8px 15px;
      font-size: 0.9rem;
  }
}

#business-partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem; /* Space between images */
  padding: 20px;
  margin-top: 3rem;
}

#business-partners img {
  max-width: 100%;
  height: auto;
  object-fit: contain; /* Ensure the image is fully visible */
  width: 100%;
  max-width: 300px; /* Set max width for each image */
  transition: transform 0.3s ease-in-out;
}

#business-partners img:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  #business-partners img {
    max-width: 200px;
  }
}

@media screen and (max-width: 480px) {
  #business-partners img {
    max-width: 150px;
  }
}

