* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    width: 100%;
}


/* Carousel Container */
#homepage1 {
    width: 100%;
    height: 78vh; /* Default height for larger screens */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  /* Carousel Item */
  .carousel-item {
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Carousel Images */
  .carousel-image {
    width: 100%; /* Ensure image scales with container width */
    height: auto; /* Adjust height based on aspect ratio */
    max-height: 100vh; /* Limit height to viewport height */
    object-fit: cover; /* Maintain aspect ratio without cropping */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    #homepage1 {
      height: 80vh; /* Adjust height for tablets */
    }
  }
  
  @media (max-width: 768px) {
    #homepage1 {
      height: 70vh; /* Adjust height for smaller tablets */
    }
  }
  
  @media (max-width: 480px) {
    #homepage1 {
      height: 50vh; /* Adjust height for mobile phones */
      object-fit: cover;
    }
  }
  