* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
*::selection {
  background-color: #f9a273;
  color: white;
}
html,
body {
  width: 100%;
  height: 100%;
}

.footer-bar {
  border-top: 0.2px solid rgb(215, 210, 210);
  margin-top: 4rem;
  width: 100%;
  /* height: 34.5rem; */
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: end;
  gap: 5rem;
 padding: 10px 20px;
  
}

.footer-bar-inner {
  width: 100%;
  height: 25rem;
  display: flex;
  padding: 30px 10px;
  justify-content: flex-start;
  gap: 3rem;
}

.column {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: start;
  gap: 1rem;
  font-family: sans-serif;
  gap: 1rem;

  
}
.column .column-heading {
 font-size: 1.5rem;
 font-weight: 550;
 color: rgb(79, 76, 76);
 font-family: Verdana, Geneva, Tahoma, sans-serif;

}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left;
}

.dropdown-content a {
  color: rgb(45, 42, 42);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn {
  color: rgb(45, 42, 42);
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
}


.hitechlogo{
  height: 50px;
}

.column .column-four {
  font-family: sans-serif;
  font-weight: 100;
  /* color: #919191; */
  cursor: pointer;
}

.column1-content {
  font-size: 18px;
  font-weight: 100;
  /* color: #919191; */
  line-height: 1.3rem;
}

.column:nth-child(1),
.column:nth-child(4) {
  width: 40%;
}

.column:nth-child(1),
.column:nth-child(2),
.column:nth-child(3),
.column:nth-child(4) {
  font-family: sans-serif;
  gap: 2rem;
}
.column-four {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.column-four a{
  text-decoration: none;
  color: black;
}

.footer-copyright {
  color: #919191;
}

.footer-copyright h5 {
  font-weight: 100;
}

.social-media-box {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.social-media-box i {
  text-decoration: none;
  font-size: 26px;
  color: black;
  cursor: pointer;
  transition: 0.5s;
}

.social-media-box i:hover {
  /* color: #29166F; */
  color: #f9a273;
}

.column h3 {
  font-size: 1.3rem;
  width: max-content;
}

.column-four h5{
  font-size: 1.3rem;
}

.column-four h5:hover{
  color: #29166F;
}


@media (max-width: 600px) {
  html,
  body {
    overflow-x: hidden;
  }

  .footer-bar-inner {
    gap: 0.5rem;
    padding: 20px 10px;
    height: max-content;
  }
  .footer-bar{
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-bar {
    padding: 2rem;
    height: auto;
  }
  .logo img {
    height: 20px;
  }
  .logo p {
    font-size: 8px;
  }

  .column1-content {
    font-size: 8px;
    line-height: 16px;
  }

  .column .column-heading{
    font-size: 0.5rem;
  }

  .column h3 {
    font-size: 10px;
  }

  .column-four h5 {
    font-size: 9px;
  }

  .social-media-box {
    gap: 0.4rem;
  }
  .social-media-box i {
    font-size: 14px;
  }

  .footer-copyright h5 {
    font-size: 10px;
  }
}

.get-help-option{
  text-decoration: none;
}

@media (max-width: 1368px) {
  .footer-bar{
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}