* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

#main {
    width: 100%;

    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 1.3rem;
}

.signup-page-form {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

#signUp-card {
    width: 100%;
    height: 76vh;
    background-color: rgb(244, 231, 215);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    border-radius: 10px;
    border: .3px solid silver;
    padding-top: 20px;
    padding-bottom: 40px;

}

#register {
    font-size: 2.5rem;
}

.signUp-input {
    padding: 10px;
    width: 85%;
    border-radius: 10px;
    border: none;
    border: 1px solid silver;
}

#signUp-btn {
    padding: 10px;
    width: 85%;
    border-radius: 20px;
    border: none;
    background-color: #FF782D;
    font-size: 1.2rem;
    color: #fff;
}



@media (min-width:700px) {

    .signup-page-form {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #signUp-card {
        width: max-content;
        padding: 0px 2rem;

    }

    .signUp-input {
        width: 20rem;
    }

    #signUp-btn {
        width: 20rem;
    }
}