/* Layout for the entire page */
.typing-page-layout {
  display: flex;
  background-color: #7a8083;
  flex-direction: row;
  min-height: 100vh; /* Ensure full viewport height on smaller screens */
}

/* Sidebar styling */
.typing-sidebar {
  width: 200px;
  padding: 20px;
  background-color: #7a8083;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
}

/* Button styles for Day 1 to Day 11 */
.day-button {
  padding: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.day-button:hover {
  background-color: #0056b3;
}

/* Main typing area */
.typing-page-body {
  flex: 1;
  padding: 20px;
  background-color: #d3dfe3;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

/* Typing language bar for selecting language and login button */
.typing-language-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.typing-login-button {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.typing-login-button:hover {
  background-color: #218838;
}

.typing-select-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.typing-nav-heading {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

.typing-controls button {
  width: max-content;
  height: max-content;
  background-color: #007bff;
  border-radius: 10px;
  padding: 10px 30px;
  border: none;
}

.typing-controls {
  display: flex;
  align-items: center;
}

.timer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* background-color: red; */
  padding: 1rem;
}

/* The box that displays the random text for typing */
.typing-word-box {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 26px;
  line-height: 1.6;
  margin-bottom: 20px;
  max-height: none;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 45vh;
  width: 55vw;
  border: 1rem solid #7a8083;
  overflow-y: auto; /* Scroll text box if content overflows */
}

/* Typing input box */
.typing-input-type-input {
  padding: 10px;
  width: 100%;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 13vh;
  border: 0.2rem solid #7a8083;
}

.typing-input {
  padding: 12px; /* Increased padding for better typing comfort */
  width: 100%; /* Full width of the container */
  font-size: 18px; /* Adjusted font size for readability */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 20px; /* Space below the input */
  border: 0.2rem solid #7a8083; /* Customized border color */
  resize: none; /* Prevent resizing of the textarea */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition on focus */
}

.typing-input:focus {
  border-color: #007bff; /* Border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Shadow effect on focus */
  outline: none; /* Remove default outline */
}

.typing-input::placeholder {
  color: #aaa; /* Placeholder text color */
  font-style: italic; /* Italicize placeholder text */
}

/* Timer and start/pause button container */
.typing-timer-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Label styling */
label {
  font-size: 18px; /* Font size for better readability */
  margin-right: 10px; /* Space between label and select */
  color: #333; /* Darker color for visibility */
}

/* Select box styling */
select {
  padding: 10px; /* Padding for comfort */
  font-size: 16px; /* Adjusted font size */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Background color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

/* Change border color on focus */
select:focus {
  border-color: #007bff; /* Highlight border color */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Shadow effect on focus */
  outline: none; /* Remove default outline */
}

/* Option styling */
option {
  padding: 10px; /* Padding for options */
  font-size: 16px; /* Font size for options */
}

.typint-box-select-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.typing-box-start-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.typing-box-start-button:hover {
  background-color: #0056b3;
}

/* Timer display */
.typing-timer-display {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Results section */
/* Result container styling */
.result {
  background-color: #f0f4f7; /* Light background for contrast */
  padding: 20px; /* Padding for space inside the container */
  border-radius: 10px; /* Rounded corners for aesthetics */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Centered text alignment */
  margin-top: 20px; /* Space above the results section */
}

/* Heading styling */
.result h5 {
  font-size: 24px; /* Larger font size for prominence */
  color: #007bff; /* Color that matches the theme */
  margin-bottom: 15px; /* Space below heading */
}

/* Paragraph styling */
.result p {
  font-size: 20px; /* Slightly larger font for readability */
  color: #333; /* Darker text color */
  margin: 5px 0; /* Space between paragraphs */
}

/* Highlighted WPM value */
.result p.wpm {
  font-weight: bold; /* Bold text for emphasis */
  color: #28a745; /* Green color to indicate success */
}

/* Highlighted Accuracy value */
.result p.accuracy {
  font-weight: bold; /* Bold text for emphasis */
  color: #dc3545; /* Red color to indicate importance */
}

/* Responsive Design */
@media (max-width: 768px) {
  .typing-page-layout {
    flex-direction: column;
  }

  .typing-sidebar {
    width: 100%;
    height: auto;
    flex-direction: row;
    padding: 10px;
    justify-content: center;
  }

  .day-button {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
    font-size: 14px;
  }

  .typing-page-body {
    padding: 10px;
  }

  .typing-word-box {
    font-size: 22px;
    height: auto;
  }

  .typing-input-type-input {
    height: 10vh;
  }
}

@media (max-width: 480px) {
  .day-button {
    font-size: 12px;
    padding: 8px;
  }

  .typing-word-box {
    font-size: 20px;
  }

  .typing-input-type-input {
    font-size: 16px;
  }

  .typing-box-start-button {
    font-size: 14px;
  }
}
