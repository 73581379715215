.quiz-container {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.quiz-card {
  text-align: center;
}

.options-list {
  list-style-type: none;
  padding: 0;
}

.options-list .option {
  padding: 10px;
  margin: 5px 0;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.options-list .option:hover {
  background-color: #dcdcdc;
}

.options-list .correct {
  background-color: #4caf50; /* Green for correct answers */
  color: white;
}

.options-list .incorrect {
  background-color: #f44336; /* Red for incorrect answers */
  color: white;
}

.result-message {
  margin: 10px 0;
  font-weight: bold;
}

.next-button {
  padding: 10px 15px;
  background-color: #2196f3; /* Blue color for Next button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #b0bec5; /* Gray when disabled */
  cursor: not-allowed;
}

.next-button:hover:not(:disabled) {
  background-color: #1976d2; /* Darker blue on hover */
}
