*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    height: 100%;
    width: 100%;
}


.admin-dashboard {
  display: flex;
  position: absolute;
}

.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  height: 100vh;
  padding: 10px 20px;
}

.sidebar h2 {
  text-align: center;
  color: white;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
}

.main-content {
  padding: 20px;
  width: 100%;
}
