.courses-container {
  padding: 1rem 12rem;
  margin-left: 1rem;
}

.course-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.course-card {
  background-color: #f4f4f4;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 300px;
  position: relative;
}

.course-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.course-info {
  padding: 10px;
  flex-grow: 1;
}

.delete-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.delete-btn:hover {
  background-color: #ff1a1a;
}
