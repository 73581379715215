/* CreateNotification.css */
.notification-wrapper {
    max-width: 500px; /* Sets a maximum width for the form */
    margin: 20px auto; /* Centers the form horizontally */
    padding: 20px; /* Adds padding inside the form */
    border: 1px solid #ccc; /* Light grey border around the form */
    border-radius: 5px; /* Rounds the corners of the form */
    background-color: #f9f9f9; /* Light background color */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  }
  
  .notification-wrapper h2 {
    text-align: center; /* Centers the heading text */
    color: #333; /* Dark grey color for the heading */
  }
  
  .notification-wrapper label {
    display: block; /* Makes each label occupy a full line */
    margin: 10px 0; /* Adds space above and below each label */
    font-size: 16px; /* Sets the font size for labels */
    color: #555; /* Medium grey color for labels */
  }
  
  .notification-wrapper input[type="text"] {
    width: 100%; /* Makes the text input full width */
    padding: 10px; /* Adds padding inside the input */
    margin-top: 5px; /* Space above the input */
    border: 1px solid #ccc; /* Light grey border for the input */
    border-radius: 4px; /* Rounds the corners of the input */
    font-size: 16px; /* Sets font size for the input */
  }
  
  .notification-wrapper input[type="radio"] {
    margin-right: 5px; /* Space to the right of the radio button */
  }
  
  .notification-wrapper button {
    width: 100%; /* Makes the button full width */
    padding: 10px; /* Adds padding inside the button */
    margin-top: 20px; /* Space above the button */
    background-color: #007bff; /* Blue background color for the button */
    color: white; /* White text color for the button */
    border: none; /* No border for the button */
    border-radius: 5px; /* Rounds the corners of the button */
    cursor: pointer; /* Changes cursor to pointer on hover */
    font-size: 16px; /* Sets font size for the button */
  }
  
  .notification-wrapper button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .data-list {
    margin-top: 20px; /* Adds space above the existing data list */
  }
  
  .data-tile {
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 5px; /* Rounded corners */
    padding: 15px; /* Padding inside the tile */
    margin: 10px 0; /* Margin above and below the tile */
    background-color: #f1f1f1; /* Light background color */
  }
  
  .data-tile.job {
    border-left: 5px solid #007bff; /* Blue border for job notifications */
  }
  
  .data-tile.notification {
    border-left: 5px solid #28a745; /* Green border for regular notifications */
  }
  
  .data-tile h4 {
    margin: 0 0 5px 0; /* Margin below the heading */
    color: #333; /* Dark grey color for the heading */
  }
  
  .data-tile p {
    margin: 0; /* Remove margin for the paragraph */
    color: #555; /* Medium grey color for the text */
  }
  