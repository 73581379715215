* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

#main {
  margin-top: 2rem;
  width: 100%;
  /* height: 80vh; */
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 1.3rem;
}

.login-page-form {
  width: 100%;
  height: max-content;
}

#Login-card {
  width: 100%;
  height: 68vh;
  background-color: rgb(244, 231, 215);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  border-radius: 10px;
  border: 1px solid silver;
}

.login-input {
  padding: 10px;
  width: 85%;
  border-radius: 10px;
  border: none;
  border: 1px solid silver;
}

.Login-btn {
  padding: 10px;
  width: 85%;
  border-radius: 20px;
  border: none;
  background-color: #ff782d;
  color: #fff;
  font-size: 1.2rem;
}

.page2 {
  width: 100%;
  height: 10rem;
  background-color: yellow;
}

@media (min-width: 700px) {
  .login-page-form {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #Login-card {
    width: max-content;
    padding: 0px 2rem;
  }

  .login-input {
    width: 20rem;
  }

  .Login-btn {
    width: 20rem;
  }
}
