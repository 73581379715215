* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: gilroy;
}
html,
body {
  width: 100%;
  height: 100%;
}
.error-page {
  width: 100%;
  height: 70vh;
  padding: 4rem 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-page h1 {
  font-family: sans-serif;
  font-size: 3rem;
}

.error-page-inner {
  width: 100%;
  height: 100%;
}

.error-page img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1000px) {
  .error-page {
    height: 60vh;
    padding: 1rem;
  }
}

@media (max-width: 600px) {
  .error-page {
    padding: 2rem;
    height: 50vh;
  }
  .error-page h1 {
    font-size: 2rem;
  }
}
