*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    height: 100%;
    width: 100%;
}

#AllBranchesMain{
    height: max-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
}

#allBranches-Imagesection{
    height: 60vh;
    width: 90vw;
    background-image: url("../../Assets/OurBranches.jpg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 3rem;
    border-radius: 25px;
    color: #FAFAFA;
    font-weight: 600;
}


#AllBranchesCards{
    height: max-content;
    width: 80vw;

}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 10px;
    
  }
  
  .card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 280px;
    height: max-content;
    overflow: hidden;
    text-align: center;
    border: 2px solid lightgray;

    
  }
  
  .card-image {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }
  
  .card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
  }

  #information-center{
    height: max-content;
    width: 100%;
    
  }

  #information-center {
    margin-top: 40px;
    padding: 20px;
  }
  
  #information-center h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .info-center-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .info-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 300px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .info-card h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .info-card p {
    margin: 5px 0;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .info-card {
      width: 90%;
    }
  
    #information-center h2 {
      font-size: 1.8rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .info-card {
      width: 100%;
    }
  
    #information-center h2 {
      font-size: 1.5rem;
    }
  }
  