/* General container styling */
.quiz-container {
  width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Quiz title */
.quiz-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Form group */
.form-group {
  margin-bottom: 15px;
}

.option-group {
  margin-bottom: 10px;
}

/* Label styling */
.form-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

/* Input field styling */
.form-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.form-input:focus {
  border-color: #4caf50;
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Submit button styling */
.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: orange;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-btn:hover:not(:disabled) {
  background-color: #45a049;
}
