*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    height: 100%;
    width: 100%;
}

.generate-certificate-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: rgb(244, 231, 215);
    margin-top: 2rem;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .generate-certificate-form {
    display: flex;
    flex-direction: column;
  }
  
  .generate-certificate-form label {
    margin-bottom: 5px;
    font-size: 1rem;
  }
  
  .generate-certificate-form input {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  .submit-btn {
    padding: 10px;
    background-color: #ff782d;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #ff703d;
  }
  
  @media (max-width: 768px) {
    .generate-certificate-container {
      width: 100%;
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .submit-btn {
      font-size: 0.9rem;
    }
  }
  