/* Container for all quizzes */
.quiz-container1 {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Quiz list and cards */
  .quiz-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }
  
  .quiz-card {
    width: 250px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: max-content;
  }
  
  .quiz-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .quiz-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-card ul li {
    padding: 8px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: background-color 0.3s;
  }
  
  .quiz-card ul li.correct {
    background-color: #4caf50;
    color: white;
  }
  
  .quiz-card ul li:hover {
    background-color: #dcdcdc;
  }
  
  /* Delete button */
  .delete-btn1 {
    padding: 8px 12px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .delete-btn:hover {
    background-color: #c0392b;
  }
  
  /* Error message */
  .error-message {
    color: red;
    text-align: center;
  }
  