.scrolling-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    border-top: 3px solid orange;
    border-bottom: 3px solid orange;
    padding: 10px 0;
    position: relative;
  }
  
  .scrolling-text {
    display: inline-block;
    animation: scroll 30s linear infinite;  /* Slowed down the scroll */
    font-size: 20px;
    color: blue;
    transition: color 0.5s ease;
  }
  
  .scrolling-text span {
    display: inline-block;
    margin-right: 10vw;  /* Reduced the gap for quicker reappearance */
    white-space: nowrap;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 550;
  }
  
  /* Keyframes for smooth scrolling animation */
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Hover effect to pause and change color */
  .scrolling-container:hover .scrolling-text {
    animation-play-state: paused;
    color: red;
  }
  