/* Swiper Container */
.swiper-container {
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
  }
  

  .card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    height: 100%;
  }
  .card-icon-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  /* Icon Image */
  .card-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    display: block; /* Ensures it's treated as a block element */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the image within the circle */
  }
  
  /* Card Title */
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-transform: uppercase;
  }
  
  /* Card Description */
  .card-description {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  
  /* Responsive breakpoints */
  @media (max-width: 1200px) {
    .swiper-container {
      width: 100%;
    }
  }
  
  @media (max-width: 900px) {
    .swiper-container {
      width: 100%;
    }
  }
  
  @media (max-width: 600px) {
    .swiper-container {
      width: 100%;
    }
  }
  