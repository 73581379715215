.create-typing-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.create-typing-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.create-typing-form label {
  margin: 10px 0 5px;
}

.create-typing-form input,
.create-typing-form textarea {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.create-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.create-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.language-selection {
  margin-top: 20px;
}

.language-selection p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

.language-selection label {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  color: #444;
  transition: color 0.3s ease;
}

.language-selection label:hover {
  color: #007bff;
}

.language-selection input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.language-selection input[type="radio"] + span {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  background-color: #f0f0f0;
  border-radius: 50%;
  border: 2px solid #ddd;
  transition: all 0.3s ease;
}

.language-selection input[type="radio"]:checked + span {
  background-color: orange;
  border-color: orange;
}

.language-selection input[type="radio"]:checked + span:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.language-selection label + label {
  margin-top: 15px;
}

/* Optional: Custom accent color for modern browsers */
.language-selection input[type="radio"]:focus + span {
  outline: 2px solid orange;
}
