*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    height: 100%;
    width: 100%;
}


/* .create-course-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: rgb(244, 231, 215);
   margin-top: 2rem;
    
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create-course-form {
    display: flex;
    flex-direction: column;
  }
  
  .create-course-form label {
    margin-bottom: 5px;
    font-size: 1rem;
  }
  
  .create-course-form input {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  .submit-btn {
    padding: 10px;
    background-color: #ff782d;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #ff783d;
  }

  #courseLevel{
    padding: 0.5rem;
    border: none;
    color: gray;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .create-course-container {
      width: 100%;
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .submit-btn {
      font-size: 0.9rem;
    }
  }
   */



   /* CreateCourse.css */

/* Wrapper to center the card */
.create-course-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

/* Card styling */
.create-course-card {
  background-color: rgb(244, 231, 215);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.create-course-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.create-course-form label {
  font-size: 1rem;
}

.create-course-form input,
.create-course-form select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-course-form input::placeholder {
  color: #888;
}

.submit-btn {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

/* Responsive Design */

/* For tablets */
@media (max-width: 1024px) {
  .create-course-card {
    padding: 30px;
  }
}

/* For mobile phones */
@media (max-width: 767px) {
  .create-course-card {
    padding: 20px;
  }

  .create-course-form input,
  .create-course-form select {
    font-size: 0.9rem;
    padding: 8px;
  }

  .submit-btn {
    padding: 10px;
  }
}
