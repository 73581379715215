*{
    margin: 0;
    padding: 0;
}

html,body{
    height: 100%;
    width: 100%;
}




/* General styling */
#About-main-page1 {
    padding: 20px;
    background-color: #FAFAFA;
  }
  
  #About-submain-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .description-btn{
    padding: 10px 25px;
    font-size: 1.2rem;
    font-weight: 550;
    background-color: #29166F;
    color: white;
    border: none;
    border-radius: 25px;
    text-decoration: none;
  }

  #About-left-description{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  #About-left-submain,
  #About-right-submain {
    flex: 1;
    min-width: 300px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  #About-right-submain img {
    width: 200%;
    height: auto;

  }

  
  
  #About-page2 {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  #About-page2-img {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .Aboutpage-hitech-img {
    max-width: 100%;
    height: auto;
  }
  
  #About-page2-description {
    padding: 20px;
  }
  
  #About-page2-description-center-div {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Responsive styling */
  @media only screen and (max-width: 768px) {
    #About-submain-page {
      flex-direction: column;
      align-items: center;
    }
  
    #About-left-submain,
    #About-right-submain {
      width: 100%;
      margin: 0;
      margin-top: 2rem;
    }
  
    #description-heading h1,
    #description-subheading h4 {
      text-align: center;
    }
  
    #About-page2-description-center-div {
      padding: 10px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .Aboutus-main-heading {
      font-size: 24px;
    }
  
    .Aboutus-main-subheading {
      font-size: 16px;
    }
  
    .description-btn {
      padding: 10px 25px;
      font-size: 1.2rem;
      font-weight: 550;
      background-color: #29166F;
      color: white;
      border: none;
      border-radius: 25px;
      text-decoration: none;
    }
  
    .About-page2-heading {
      font-size: 22px;
      text-align: center;
    }
  
    .About-us-paragraph {
      font-size: 14px;
      text-align: justify;
    }
  }