.get-all-typing-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.typing-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.typing-card {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.typing-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.typing-card p {
  font-size: 1rem;
  color: #333;
}
