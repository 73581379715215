*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    height: 100%;
    width: 100%;
}

#contect-page1 {
    padding: 30px 80px 0px 80px;
   
  }
  
  #contect-map-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  
  #contect-section {
    flex: 1;
    margin-right: 20px;
  }
  
  #contect-section-sub-div {
    max-width: 600px;
  }
  
  .heading-data {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .subheading-data {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  #all-contect-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contect-section-phone-box {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .icon-div, .location-icon-div {
    width: 30px;
    height: 30px;
  }
  
  .phone-txt {
    font-weight: bold;
  }
  
  .phone-number {
    color: #555;
  }
  
  #map-section {
    flex: 1;
  }
  
  .map-img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Media Query for tablets */
  @media (max-width: 768px) {
    #contect-map-section {
      flex-direction: column;
      align-items: flex-start;
    }
  
    #contect-section {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .heading-data {
      font-size: 1.5rem;
    }
  
    .subheading-data {
      font-size: 1rem;
    }
  }
  
  /* Media Query for mobile devices */
  @media (max-width: 480px) {
    .heading-data {
      font-size: 1.2rem;
    }
  
    .subheading-data {
      font-size: 0.9rem;
    }
  
    .phone-txt-number {
      font-size: 0.9rem;
    }
  
    .icon-div, .location-icon-div {
      width: 25px;
      height: 25px;
    }
  }
  