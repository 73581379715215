.certificates-list {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.search-input {
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.certificate-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.certificate-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.certificate-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.certificate-card p {
  margin: 8px 0;
  font-size: 16px;
  color: #333;
}

.certificate-card strong {
  color: #000;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.loading,
.error {
  text-align: center;
  font-size: 18px;
  color: #999;
  margin-top: 50px;
}
