.news-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 1rem;
}

.news-container {
  width: 500px;
  height: 200px;
  overflow: hidden;
  border: 1px solid #ccc;
  position: relative;
  background-color: white;
  font-size: 22px;
}

.notification-heading {
  background-color: #003366;
  color: white;
  padding: 5px;
  margin: 0;
  text-align: center;
}

.news-content {
  padding: 20px;
  position: absolute;
  bottom: 0;
  animation: scrollUp 25s linear infinite;
}

.news-item {
  color: green;
  margin: 10px 0;
  cursor: pointer;
}

.notification-heading {
  position: relative;
  z-index: 2;
}

@keyframes scrollUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* Pauses the scrolling when hovered */
.paused {
  animation-play-state: paused;
}
