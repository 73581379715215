.splash-screen {
  width: 100%;
  height: 100vh;
  background-color: #e7dedc;
  display: flex;
  align-items: center;
  justify-content: center;
}


.splash-screen img {
  height: 200px;
}
