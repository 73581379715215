/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

#navbar {
  height: 10vh;
  width: 100%;
  background-color: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: sticky;
  top: 0px;
  z-index: 100;
}

#logo {
  width: max-content;
  height: 60px;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

#nav-manu {
  width: 45rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 0px 1.5rem;
  gap: 2rem;
  transition: 2s;
  
  
}

.nav-manus{
  color: black;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 540;
  text-decoration: none;
  position: relative;
}

.nav-manus:after{
  content: "";
  position: absolute;
  background-color: #29166F;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -5px;
  transition: 0.3s;
}

.nav-manus:hover:after{
  width: 100%;
}

.nav-manus:hover{

  color: #29166F;
 
}




#nav-manu a:hover {
  background-color: #fff;
}

#navLogRegis {
  width: 16rem;
  display: flex;
  align-items: center;
  justify-content: end;
}

#navLogRegis a {
  text-decoration: none;
  padding: 20px 15px;
  cursor: pointer;
  width: max-content;
}

#navLogRegis a:hover {
  background-color: #fff;
}

.humburger-menu,
.close-menu {
  display: none;
}

.user-profile {
  width: max-content;
  height: max-content;

  gap: 5px;
  display: flex;
  align-items: center;
}

.user-profile-name {
  font-size: 18px;
  font-weight: 200;
  font-family: sans-serif;
}

.user-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 100px;
}

.user-profile-pic img {
  width: 100%;
  height: 100%;
}

.user-profile-pic1 {
  width: max-content;
  height: max-content;
 
  border: 1px solid orange;
  margin-left: 20px;
  padding: 8px;
  border-radius: 100px;
  cursor: pointer;
}

.user-profile-pic1:hover {
  border: 1px solid black;
}

.user-profile-pic1 i {
  font-size: 16px;
  color: orange;
}

@media (max-width: 850px) {
  #navLogRegis {
    display: none;
  }
  .humburger-menu,
  .close-menu {
    display: none;
  }
}

@media (max-width: 600px) {
  #navbar {
    justify-content: start;
    gap: 2rem;
    padding: 0px 10px;
  }
  #nav-manu {
    display: none;
  }
  #navLogRegis {
    display: none;
  }
  .humburger-menu,
  .close-menu {
    display: block;
  }
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

#navbar {
  height: 10vh;
  width: 100%;
  background-color: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 100;
  padding: 0 20px;
  /* overflow-x: hidden; */
}

#logo {
  width: max-content;
  height: 60px;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.logos img{
  height: 60px;
}

#nav-manu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 1.5rem;
  gap: 1.5rem;
  transition: 0.3s;
}

.nav-manus {
  color: black;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 540;
  position: relative;
}

#right-nav-manus .nav-manus{

  height: 15px;
}

.nav-manus:after {
  content: "";
  position: absolute;
  background-color: #29166F;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -5px;
  transition: 0.3s;
}

.nav-manus:hover:after {
  width: 100%;
}

.nav-manus:hover {
  color: #29166F;
}

#navLogRegis {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

#navLogRegis a {
  text-decoration: none;
  padding: 20px 15px;
  cursor: pointer;
  width: max-content;
}

.humburger-menu, .close-menu {
  display: none;
}

.user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
}

.user-profile-name {
  font-size: 18px;
  font-weight: 200;
  font-family: sans-serif;
  margin-top: 0.9rem;
  font-size: 1rem;
}

.user-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 100px;
}

.user-profile-pic img {
  width: 100%;
  height: 100%;
}

.user-profile-pic1 {
  border: 1px solid orange;
  padding: 8px;
  border-radius: 100px;
  cursor: pointer;
}

.user-profile-pic1:hover {
  border: 1px solid black;
}

.user-profile-pic1 i {
  font-size: 16px;
  color: orange;
}

/* Large Desktops */
@media (max-width: 1440px) {
  #navbar {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
  }

  #nav-manu {
    gap: 1.5rem;
  }

}


/* Laptops and Tablets (1024px to 1280px) */
@media (max-width: 1280px) {
  #nav-manu {
    gap: 1.2rem;
  }
  
  #logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logos img{
    height: 40px;
    font-size: 1rem;
    color: black;
  }
  .user-profile-name {
    font-size: 16px;
  }
}

/* Tablets (768px to 1024px) */
@media (max-width: 1024px) {
  #nav-manu {
    gap: 1rem;
    font-size: 0.5rem;
  }
  #navbar {
    padding: 0 10px;
  }

  .logos img{
    height: 40px;
  }

  #navLogRegis {
    width: 12rem;
  }
}

@media (max-width: 1180px){
    .nav-manus{
      font-size: 0.9rem;
      color: rgb(0, 0, 0);
      font-weight: 550;
    }
}

/* Small Tablets and Large Mobiles (600px to 768px) */
@media (max-width: 768px) {
  #navbar {
    height: 8vh;
  }
  #nav-manu {
    display: none; /* Hide menu on smaller screens */
  }
  .humburger-menu {
    display: block;
  }
}

/* Mobile Phones (max-width: 600px) */
@media (max-width: 600px) {
  #navbar {
    justify-content: space-between;
  }
  #logo {
    height: 45px;
  }

  .logos img{
    height: 40px;
  }

  #navLogRegis {
    display: none;
  }
  .humburger-menu {
    display: block;
  }
}

/* Very Small Devices (max-width: 480px) */
@media (max-width: 480px) {
  #navbar {
    padding: 0 5px;
  }
  #logo {
    height: 40px;
  }
  .logos img{
    height: 40px;
  }
  .user-profile-name {
    font-size: 14px;
  }
  .user-profile-pic {
    width: 25px;
    height: 25px;
  }
}
