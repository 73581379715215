* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

#parent-container {
  height: 90vh;
  width: 100vw;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-drop-area {
  position: relative;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-drop-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 10px solid transparent;
  border-top: 10px solid orange;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spin 2s linear infinite;
}

.drag-drop-content {
  height: 95%;
  width: 95%;
  background-color: green;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 2rem;
  position: relative;
}

.cancle-btn {
  width: 50px;
  height: 50px;
  background-color: black;
  border-radius: 100px;
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.drag-drop-area.active .drag-drop-content {
  background-color: yellow;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
