.certificate-card {
  display: flex;
  align-items: flex-start;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
}

.certificate-card-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px; /* Space between the image and the content */
}

.certificate-card-content {
  flex-grow: 1; /* This makes sure the content takes up the remaining space */
}

.certificate-card-content h2 {
  margin-bottom: 10px;
}

.certificate-card-content p {
  margin: 5px 0;
}
