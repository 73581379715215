* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: gilroy;
}
html,
body {
  width: 100%;
  height: 100%;
}

.sidebar-page,
.sidebar-page1 {
  display: none;
}

.menu-box {
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  width: 100%;
  height: 100%;
}

.menu-box a {
  font-family: sans-serif;
  text-decoration: none;
  font-size: 18px;
  color: whitesmoke;
  padding: 10px 20px;
  border-radius: 8px;
}

.menu-icon-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px 20px;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .overlay-box {
    width: 100%;
    background-color: transparent;
    position: relative;
    background-color: lightblue;
  }
  .sidebar-page,
  .sidebar-page1 {
    display: block;
    width: 60%;
    height: 100vh;
    background-color: #f9a273;
    color: white;
    position: absolute;
    z-index: 90;
    top: 0px;
    left: -60%;
    transition: all ease 1s;
    padding: 7rem 1rem;
  }
  .sidebar-page1 {
    transition: all ease 1s;
    left: 0rem;
  }
}
