/* DeleteAd.css */

/* Container for the ad cards */
.ad-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

/* Card styles */
.ad-card {
  position: relative; /* For positioning delete button */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 250px;
  text-align: center;
  transition: transform 0.2s;
}

/* Image styles */
.ad-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

/* Delete button styles */
.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 50px;
  height: 50px;
  border-radius: 100px; 
  padding: 20px;
}

.delete-btn:hover {
  background-color: #ff1a1f;
  
}

/* Hover effect on the card */
.ad-card:hover {
  transform: scale(1.05);
}

/* Responsive layout */
@media (max-width: 768px) {
  .ad-card {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .ad-card {
    width: 150px;
  }
}
