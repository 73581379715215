/* Course Container */
#course-main-page {
  padding: 20px;
}

#course-submain-page {
  max-width: 1200px;
  margin: auto;
}

#course-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.course-h1-heading {
  font-size: 2rem;
}

.course-heading-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.course-cards-container {
  margin-top: 20px;
}

/* Course Card Styling */
.course-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  height: 70vh;
  background-color: #F9F9F9;
  box-shadow: 2px 9px 15px rgba(0, 0, 0, 0.3);
}

.course-card-img-section {
  margin-bottom: 15px;
}

.course-card-img {
  max-width: 100%;
  height: 30vh;
  border-radius: 10px;
}

.course-card-content {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-name {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
  text-transform: uppercase;
}

.course-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.course-details h5 {
  font-size: 0.9rem;
  color: #444;
}

.course-btn {
  padding: 10px 30px;
  font-size: 1.2rem;
  font-weight: 550;
  background-color: #29166F;
  color: #FBEAEB;
  border: none;
  border-radius: 25px;
  text-decoration: none;
  margin-top: 2rem;
  border: 2px solid #29166F;
  transition: 0.3s;
}

/* Swiper Pagination Dots */
.swiper-pagination-bullet {
  background: #007bff;
}

.swiper-pagination-bullet-active {
  background: #28a745;
}

.swiper-button-prev,
.swiper-button-next {
  color: #007bff;
}


@media (max-width: 768px) {
  .course-card {
    padding: 15px;
  }
}
