/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    height: 100%;
    width: 100%;
}

#ugCourses-mainpage{
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}

#mainImagediv{
    height: 50vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

#mainImagediv img{
    width: 80vw;
    height: 100%;
    border-radius: 2rem;
}

#mainCards-section{
    height: max-content;
    width: 100%;
    background-color: lightblue;
    margin-top: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-around;
    
    padding: 2rem;
    gap: 10px;
} */


#ugCourses-mainpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#mainImagediv {
    display: flex;
    align-items: center;
    /* Vertically centers the image and text */
    justify-content: space-around;
    /* Ensures the content is aligned to the left */
    gap: 3rem;

    padding: 20px;
    
}

#mainImagediv img {
    max-width: 300px;
    /* Controls the image size */
    height: auto;
    /* Maintain the aspect ratio */
    object-fit: cover;
    /* Ensures the image fits the box nicely */
}

#mainImagediv h4 {
    flex: 1;
    /* Allows the description to take up the remaining space */
    font-size: 18px;
    line-height: 1.5;
    /* Better readability */
}




.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* Space between cards */
    padding: 20px;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 15px;
    text-align: center;
}

.card-content h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.card-content p {
    font-size: 1em;
    margin: 5px 0;
}