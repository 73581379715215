.get-student-page {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.search-bar {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar input {
  flex: 1;
  padding: 8px;
  font-size: 16px;
}

.search-bar button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.error {
  color: red;
  margin-top: 10px;
}

.certificate-info {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

.certificate-info h3 {
  margin-bottom: 10px;
}
